import '../styles/globals.css'
import {AuthProvider} from "../context/AuthContext";
import Header from '../components/Header';
// core styles shared by all of react-notion-x (required)
import 'react-notion-x/src/styles.css'

// used for code syntax highlighting (optional)
import 'prismjs/themes/prism-tomorrow.css'

// used for rendering equations (optional)
import 'katex/dist/katex.min.css'

import dynamic from 'next/dynamic';
const ProgressBar = dynamic(() => import('../components/ProgressBar'), { ssr: false });



function MyApp({ Component, pageProps }) {
  return (
    <>
    <AuthProvider>
      <ProgressBar />
      <Component  {...pageProps} />
    </AuthProvider>
    </>
  )
}

export default MyApp
