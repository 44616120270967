import axios from "axios";

export const leapyApiUrl = 'https://leapy-api-rails.herokuapp.com'

export async function getTracks() {
  try{
    const response = (process.env.NODE_ENV === 'production')
                        ? await axios.get(`${leapyApiUrl}/api/v1/tracks`)
                        : await axios.get('http://localhost:3000/api/v1/tracks')
    return response.data // Array of tracks
  } catch(error){
    console.error(error)
  }
}


export async function getTrack(id) {
  try{
    const response = (process.env.NODE_ENV === 'production')
                        ? await axios.get(`${leapyApiUrl}/api/v1/tracks/${id}`)
                        : await axios.get(`http://localhost:3000/api/v1/tracks/${id}`)
    return response.data // Array of tracks
  } catch(error){
    console.error(error)
  }
}
