import { createContext, useEffect, useState } from 'react';
import { setCookie} from 'nookies';
import { useRouter } from 'next/router'
import { leapyApiUrl } from '../lib/leapy-api';


export const AuthContext = createContext([{}, function(){}])

export const AuthProvider = ({ children }) => {
  const [user , setUser] = useState(null)
  const router = useRouter()

  const isAuthenticated = false; //Autenticated if user information exists


  useEffect(() => {
    const getUserFromLocalStorage =  () => {
      try {
        if (typeof window !== "undefined") {
          const localStorageUser = localStorage.getItem('user')
          setUser(JSON.parse(localStorageUser))
          console.log('carregando user do local storage: ', localStorageUser)
        }
      } catch(error){
          console.error(error)
      }
    }
    getUserFromLocalStorage()
  }, [])

  async function signIn(data){
    // Send the data to the server in JSON format.
    const JSONdata = JSON.stringify(data)

    // API endpoint where we send form data.
    // Verify is DEV or PROD
    const endpoint = (process.env.NODE_ENV === 'production') ?  `${leapyApiUrl}/api/auth/sign_in` :  'http://127.0.0.1:3000/api/auth/sign_in'
    console.log('Auth Context: endpoint', endpoint)
    // Form the request for sending data to the server.
    const options = {
      // The method is POST because we are sending data.
      method: 'POST',
      // Tell the server we're sending JSON.
      headers: {
        'Content-Type': 'application/json',
      },
      // Body of the request is the JSON data we created above.
      body: JSONdata,
    }

    try {
      // Send the form data to our forms API on Vercel and get a response.
      const response = await fetch(endpoint, options)
      const result  = await response.json()
      console.log('JSON',result)

      // Get access-token
      const accessToken = response.headers.get('access-token')
      console.log('accessToken', accessToken)

      // Save cookie on browser
      setCookie(undefined, 'leapy', accessToken, {
        maxAge: 30 * 24 * 60 * 60, // 30 days
        path: '/',
      })

      // Sava response information in state component
      // setApiResponse(response)
      // Save user information in state component
      const user = result.data
      setUser(user)

      // To avoid running into window is undefined or document is undefined errors
      if (typeof window !== "undefined") {
        localStorage.setItem('user', JSON.stringify(user))
      }

      if (accessToken){
        router.push(`/user/apprentice/home`)
        // router.push('/page')
      }
      return result
    } catch (err) {
      console.error();(err)
    }


  }

  return(
    <AuthContext.Provider value={{ user, isAuthenticated, signIn }}>
      {children}
    </AuthContext.Provider>
  )
}
