import Link from "next/link";

const Header = () => {
  return (
    <header className='border h-[2.5rem] w-full bg-leapy-white'>
      <nav>
        <div className='flex gap-4'>
          {/* Nome */}

          <div>
            <button>Home</button>
            <div></div>
          </div>
          {/* Trilha */}
          <div>
            <button>Trilhas</button>
            <div></div>
          </div>
        </div>
      </nav>
    </header>

  );
};
export default Header;
